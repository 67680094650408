import "./App.css";
import Download from "./compenents/Download";
import Experience from "./compenents/Experience";
import Footer from "./compenents/Footer";
import Header from "./compenents/Header";
import Hero from "./compenents/Hero";
import Search from "./compenents/Search";

function App() {
  return (
    <div className="App text-white overflow-hidden">
      <Header />
      <Hero />
      <Experience />
      <Search />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
