import React from "react";
import Feature from "./Feature";

function Experience() {
  return (
    <div
      className="experience_section relative flex flex-col items-center justify-start
    px-[5rem] bg-[#020917] h-[60rem] pt-[18rem] mt-[-10rem]  z-[2] 
    rounded-b-[5rem]
    ">
      <img
        src={require("../img/Path 318.png")}
        alt=""
        className="w-[5rem]"
      />

      <div className="headline flex flex-col items-center text-[2rem]">
        <span>
          <b>Music Experience</b>
        </span>
      </div>

      <div className="features flex items-center justify-around mt-[6rem] w-[100%]">
        <Feature icon="Group 2" title="For Live Music" />
        <Feature icon="music icon" title="For Daily Music" />
        <Feature icon="Group 4" title="For Artists" />
      </div>
    </div>
  );
}

export default Experience;
